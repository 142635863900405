<template>
  <footer :class="$style['footer']">
    <Logo :class="$style['footer__logo']" />
  </footer>
</template>
<style module>
.footer {
  background-color: var(--background-base);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xxxl);
}

.footer__logo {
  width: 200px;
}

</style>
